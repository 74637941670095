import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';

const Success = ({ history, location }) => {
  const { state } = location;
  const lab = useSelector((s) => s.lab);
  const email = lab.lab_customer_service_email || lab.lab_email;

  useEffect(() => {
    if (!location.state) {
      history.replace('/');
    }
  }, []);

  if (!state) return null;
  const { order } = state;

  const showSummary = () => {
    // navigate to summary page and send order state
    history.push('/summary', { order });
  };

  return (
    <Container>
      <Helmet title={`${lab.lab_name} Order Complete!`} />
      <Box mt={4} maxWidth={800} marginX="auto">
        <Typography paragraph align="center" variant="h1">
          Order Created Successfully!
        </Typography>
        <Typography paragraph align="center">
          Your order number is {state.orderId}. <br />
          The email you used is {order.customer.email}.
        </Typography>
        <Typography paragraph align="center">
          You should receive an email shortly with a summary of your order.
        </Typography>
        <Typography paragraph align="center">
          If you ordered during normal business hours we might begin working on
          your order shortly. If you think you made a mistake or need to change
          your order please contact us immediately at {lab.lab_phone} or email
          us at <a href={`mailto:${email}`}>{email}</a>.
        </Typography>
      </Box>
      <Box textAlign="center" my={4}>
        <Button variant="outlined" onClick={showSummary}>
          View Order Summary
        </Button>{' '}
        <Button variant="contained" color="primary" href="/">
          Back to Home Page
        </Button>
      </Box>
    </Container>
  );
};

export default Success;
