import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik, Field, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import CheckoutForm from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import Spin from 'components/Spin';
import { useDispatch, useSelector } from 'react-redux';
import { updateCart, calculatePrice } from 'store/actions/cart';
import { createCart } from 'utils/api';
import { RadioGroup } from 'components/form/Formik';
import Button from 'components/Button';
import * as Sentry from '@sentry/react';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { Alert } from '@material-ui/lab';

const MAX_RETRIES = 3;
const INITIAL_RETRY_DELAY = 1000; // 1 second

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    '& p:first-child': {
      marginRight: 'auto',
    },
  },
  paymentText: {
    display: 'flex',
    marginTop: 12,
  },
  btnxl: {},
  btnLine2: {
    fontSize: 'small',
  },
  tou: {
    marginTop: '1rem',
  },
  bold: {
    fontWeight: 500,
  },
  padBottom: {
    marginBottom: '1rem',
  },
}));

const Payment = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { payment_options, tos_url, customization } = useSelector((s) => s.lab);
  const cart = useSelector((s) => s.cart);
  const { enqueueSnackbar } = useSnackbar();
  const [stripePromise, setStripePromise] = useState(null);
  const [loadingStripe, setLoadingStripe] = useState(true);
  const [stripeError, setStripeError] = useState(null);

  useEffect(() => {
    if (!cart.items.length) {
      history.push('/');
    } else if (!cart.shippingMethod) {
      history.push('/checkout/shipping');
    } else {
      dispatch(calculatePrice());
    }
  }, []);

  useEffect(() => {
    const loadStripeJs = async (retryCount = 0) => {
      try {
        const stripe = await loadStripe(process.env.RAZZLE_APP_STRIPE_KEY);
        setStripePromise(stripe);
        setLoadingStripe(false);
      } catch (error) {
        console.error(
          `Failed to load Stripe.js (attempt ${retryCount + 1}):`,
          error
        );
        Sentry.captureException(error);

        if (retryCount < MAX_RETRIES) {
          const delay = INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
          console.log(`Retrying loading Stripe.js in ${delay}ms...`);
          setTimeout(() => loadStripeJs(retryCount + 1), delay);
        } else {
          console.log('Failed to load Stripe.js after maximum retries');
          setLoadingStripe(false);
        }
      }
    };

    loadStripeJs();
  }, []);

  const onTaxExemptConfirmChange = (e) => {
    const { checked } = e.target;
    Sentry.captureMessage('Tax Exempt checkbox checked');
    dispatch(updateCart({ isTaxExemptConfirmed: checked }));
    dispatch(calculatePrice());
  };

  const onToken = async (token) => {
    await submitCart('stripe', token);
  };

  const onPaymentSubmit = async (values) => {
    Sentry.captureMessage(`SOSV2 payment submitted: ${values.paymentMethod}`);
    return await submitCart(values.paymentMethod);
  };

  const submitCart = async (paymentMethod, token) => {
    setStripeError(null);
    try {
      const payload = {
        folderId: cart.sessionId,
        lineItems: cart.items,
        shipmethodId: cart.shippingMethod.id,
        customer: cart.customer,
        shippingAddress: cart.shippingAddress,
        orderNotes: cart.orderNotes,
        jobRef: cart.jobRef,
        userId: cart.userId,
        orderOptions: cart.orderOptions,
        isTaxExemptConfirmed: cart.isTaxExemptConfirmed,
        paymentMethod,
        token,
      };
      const cartResp = await createCart(payload);
      dispatch(
        updateCart({
          cartId: cartResp.id,
          paymentMethod,
        })
      );
      history.replace('/checkout/upload');
    } catch (e) {
      console.log(e);
      Sentry.captureMessage(`Checkout error: ${e.message}`);
      enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 5000 });
      setStripeError(e.message);
    }
  };

  const paymentOptions = [];

  if (payment_options) {
    if (payment_options.enable_stripe) {
      paymentOptions.push({
        label: 'Credit / Debit Card',
        value: 'stripe',
      });
    }
    if (payment_options.enable_lab_pickup) {
      paymentOptions.push({
        label: payment_options.lab_pickup_label,
        value: 'lab_pickup',
      });
    }
  }

  if (!cart.shippingMethod) return null;

  return (
    <Container maxWidth="sm">
      <Box mt={4}>
        <Typography variant="h6">Payment Options</Typography>
        <Typography>
          Please choose a payment option below. We use Stripe for our secure
          credit card processing. Once payment is complete you will be shown the
          progress of your file(s) uploading, make sure you keep that page open
          until it completes!
        </Typography>
      </Box>
      <Box my={2}>
        <Card>
          <CardContent>
            {!cart.price ? (
              <Spin spinning />
            ) : (
              <Spin spinning={cart.updatingPrice}>
                <Box className={classes.row}>
                  <Typography className={classes.bold}>
                    Cart Sub Total
                  </Typography>
                  <Typography>
                    ${cart.price.lineItemsTotal.toFixed(2)}
                  </Typography>
                </Box>
                {cart.price.optionsTotal != 0 && (
                  <Box className={classes.row}>
                    <Typography className={classes.bold}>
                      Checkout Options
                    </Typography>
                    <Typography>
                      ${cart.price.optionsTotal.toFixed(2)}
                    </Typography>
                  </Box>
                )}
                <Box className={classes.row}>
                  <Typography className={classes.bold}>Shipping</Typography>
                  <Typography>
                    ${(cart.price.shippingTotal || 0).toFixed(2)}
                  </Typography>
                </Box>
                <Box className={classes.row}>
                  <Typography className={classes.bold}>
                    Sales Tax{' '}
                    {cart.price.isTaxExempt && cart.isTaxExemptConfirmed
                      ? '(Exempt - Resale)'
                      : cart.price.taxTotal === 0
                      ? ''
                      : ` at ${(cart.price.taxRate * 100).toFixed(2)}%`}
                  </Typography>

                  <Typography>
                    ${(cart.price.taxTotal || 0).toFixed(2)}
                  </Typography>
                </Box>
                {cart.price.isTaxExempt ? (
                  <>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cart.isTaxExemptConfirmed}
                            onChange={onTaxExemptConfirmChange}
                            name="resaleConfirmed"
                            size="small"
                          />
                        }
                        label="This order is for resale"
                      />
                    </FormGroup>
                    <Typography variant="caption">
                      {customization.reseller_message2}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="caption">
                    {customization.reseller_message1}
                  </Typography>
                )}
                <Divider style={{ marginTop: 4, marginBottom: 4 }} />
                <Box className={classes.row}>
                  <Typography className={classes.bold}>Total</Typography>
                  <Typography className={classes.bold}>
                    ${(cart.price.grandTotal || 0).toFixed(2)}
                  </Typography>
                </Box>
                <Divider style={{ marginTop: 4, marginBottom: 4 }} />
                <Box className={classes.paymentText}>
                  <Typography variant="caption">
                    {customization.cart_summary}
                  </Typography>
                </Box>
              </Spin>
            )}
          </CardContent>
        </Card>
      </Box>
      <Box mb={2}>
        <Card>
          <CardContent>
            <Typography className={classes.bold}>
              Shipping Method: {cart.shippingMethod.shippingmethod}
            </Typography>

            {cart.shippingMethod.shippingmethod === 'Email' ? (
              <Typography variant="body2">
                We will email your images to{' '}
                <span className={classes.bold}>{cart.customer.email}</span>.
              </Typography>
            ) : cart.shippingMethod.shiptype === 'dropship' ? (
              <>
                <Typography variant="body2">
                  {cart.shippingAddress.name}
                </Typography>
                <Typography variant="body2">
                  {cart.shippingAddress?.businessName}
                </Typography>
                <Typography variant="body2">
                  {cart.shippingAddress?.address1}
                </Typography>
                <Typography variant="body2">
                  {cart.shippingAddress?.address2}
                </Typography>
                <Typography variant="body2">
                  {cart.shippingAddress?.city}, {cart.shippingAddress?.state}{' '}
                  {cart.shippingAddress?.zip}
                </Typography>
              </>
            ) : cart.shippingMethod.shiptype === 'ship' ? (
              <>
                <Typography variant="body2">
                  {cart.customer.firstName} {cart.customer.lastName}
                </Typography>
                <Typography variant="body2">
                  {cart.customer?.businessName}
                </Typography>
                <Typography variant="body2">
                  {cart.customer?.address1}
                </Typography>
                <Typography variant="body2">
                  {cart.customer?.address2}
                </Typography>
                <Typography variant="body2">
                  {cart.customer?.city}, {cart.customer?.state}{' '}
                  {cart.customer?.zip}
                </Typography>
              </>
            ) : cart.shippingMethod.shiptype === 'pickup' ? (
              <Typography variant="body2">
                We will email you once your order is ready.
              </Typography>
            ) : (
              ''
            )}
            {cart.price?.addressError && (
              <Alert severity="error" className={classes.padBottom}>
                {cart.price.addressError}. Are you sure this is the correct
                address?
                <br />
                <Button
                  component={Link}
                  to="/checkout/address"
                  color="inherit"
                  size="small"
                  style={{ marginTop: 8 }}
                >
                  Update Shipping Address
                </Button>
              </Alert>
            )}
          </CardContent>
        </Card>
      </Box>
      <Box mb={4}>
        <Card>
          <CardContent>
            <Formik
              initialValues={{
                paymentMethod: cart.paymentMethod,
              }}
              onSubmit={onPaymentSubmit}
            >
              {({ values, handleSubmit, isSubmitting }) => {
                return (
                  <Form>
                    <Typography className={classes.bold}>
                      Select Payment Type:
                    </Typography>
                    {stripeError && (
                      <Alert severity="error">{stripeError}</Alert>
                    )}
                    <RadioGroup
                      name="paymentMethod"
                      label=""
                      options={paymentOptions}
                      required={true}
                    />
                    {values.paymentMethod === 'lab_pickup' &&
                      customization.cod_message && (
                        <Alert severity="info" className={classes.padBottom}>
                          {customization.cod_message}
                        </Alert>
                      )}
                    {values.paymentMethod === 'stripe' ? (
                      loadingStripe ? (
                        <Spin spinning text="Loading payment system..." />
                      ) : stripePromise ? (
                        <Elements stripe={stripePromise}>
                          <CheckoutForm onToken={onToken} />
                        </Elements>
                      ) : (
                        <Alert severity="error">
                          Failed to load payment system. please use the "Other"
                          payment option and we will send you a link to pay.
                        </Alert>
                      )
                    ) : (
                      <Button
                        fullWidth
                        disabled={isSubmitting || !values.paymentMethod}
                        className={classes.btnxl}
                        style={{ display: 'block' }}
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                      >
                        <Typography variant="h6">
                          Complete Order <br />
                        </Typography>
                        <Typography className={classes.btnLine2}>
                          Next we'll show your image upload progress
                        </Typography>
                      </Button>
                    )}
                    <Typography
                      variant="caption"
                      component="p"
                      align="center"
                      className={classes.tou}
                    >
                      By submitting an order you agree to our{' '}
                      <a target="_blank" href={tos_url}>
                        Terms of Use
                      </a>
                    </Typography>
                  </Form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default Payment;
