import { Helmet } from 'react-helmet';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

const Contact = () => {
  const lab = useSelector((s) => s.lab);
  console.log(`s.lab`, lab);
  const email = lab.lab_customer_service_email || lab.lab_email;
  return (
    <Box marginTop={2}>
      <Helmet title={`${lab.lab_name} - Contact Us`}>
        <meta name="description" content="Contact us." />
      </Helmet>
      <Container>
        <Typography variant="h1">{lab.lab_name}</Typography>
        <Typography paragraph>
          {lab.customization.contact_description}
        </Typography>
        <Typography component="address">
          {lab.lab_name}
          <br />
          {lab.lab_address}
          <br />
          {lab.lab_city}, {lab.lab_state} {lab.lab_zip} <br />
          {lab.lab_phone}
          <br />
          <a href={`mailto:${email}`}>{email}</a>
          <br />
          <a href={lab.lab_website}>{lab.lab_website}</a>
        </Typography>
        <Typography paragraph>{`Hours: ${lab.lab_hours}`}</Typography>
        {lab.tos_url && (
          <Typography paragraph>
            View our{' '}
            <a target="_blank" href={lab.tos_url}>
              Terms of Use
            </a>
            .
          </Typography>
        )}
      </Container>
    </Box>
  );
};

export default Contact;
