import each from 'lodash/each';
import CURRENCIES from 'constants/currencies';

const DPI = 300;
const REGEXP_MIME_TYPE_IMAGES = /^image\/\w+$/;
const TIFF_MIME_TYPE_IMAGES = /^image\/tiff?$/;

//needed so numbers sort properly, otherwise 1,25,100 is 1,100,25
function compareNumbers(a, b) {
  return a - b;
}

export const inchToPixels = (inch) => Math.round(DPI * inch);

export const getRotationFromImage = (file, imageNode) => {
  return new Promise((resolve) => {
    const { width, height } = imageNode;
    const nodeWidth = inchToPixels(width);
    const nodeHeight = inchToPixels(height);
    const fileAsDataURL = window.URL.createObjectURL(file);
    const img = new Image();
    let imgOrientation = 'vertical';
    let nodeOrientation = 'vertical';
    img.onload = function () {
      const { naturalWidth, naturalHeight } = img;
      URL.revokeObjectURL(img.src);
      const nodeRatio = nodeWidth / nodeHeight;
      const imgRatio = naturalWidth / naturalHeight;
      if (nodeRatio === 1 || imgRatio === 1) {
        console.log('node or image is square, no rotation needed');
        resolve();
      } else {
        if (nodeRatio > 1) {
          nodeOrientation = 'horizontal';
        }
        if (imgRatio > 1) {
          imgOrientation = 'horizontal';
        }
        if (imgOrientation !== nodeOrientation) {
          resolve(-90);
        } else {
          resolve();
        }
      }
    };
    img.src = fileAsDataURL;
  });
};

export const applyCanvasRotation = (product) => {
  //console.log('applyCanvasRotation');
  const { width, height, template } = product;
  product.width = height;
  product.height = width;
  product.zoomRatio = getZoom(product);
  const imgNode = template.nodes[0];
  //console.log(`imgNode`, imgNode);
  const { width: imgWidth, height: imgHeight, x: imgX, y: imgY } = imgNode;
  imgNode.width = imgHeight;
  imgNode.height = imgWidth;
  imgNode.x = imgY;
  imgNode.y = imgX;
  return product;
};

export const getZoom = (product) => {
  if (!product) return 1;
  let multiplier = 1;
  let mobileMultiplier = 1;
  if (product.supersize) {
    multiplier = 1.5;
    mobileMultiplier = 1.35;
  }
  //console.log('getZoom function');
  //console.log('product :>> ', product);
  const { innerWidth, innerHeight } = window;
  // you have to worry about both width and height as the user could rotate the device
  const { width, height } = product;
  // what about node width and height - 7/29/2022
  //console.log(`innerWidth, innerHeight`, innerWidth, innerHeight);
  //what is the max side of the image node - width or height?
  // 7/24/2022 what if there is a border? we're checking overall template size but the ratio returned is for the size of the image node
  const maxSide = width > height ? width : height;
  const minSide = width < height ? width : height;

  const maxInnerSide = innerWidth > innerHeight ? innerWidth : innerHeight;
  const minInnerSide = innerWidth < innerHeight ? innerWidth : innerHeight;
  //console.log('minInnerSide, maxInnerSide :>> ', minInnerSide, maxInnerSide);
  const maxSidePixels = inchToPixels(maxSide);

  //console.log('is mobile?', maxInnerSide < 901 && minInnerSide < 450);
  //mobile devices
  // 2/2023 too many breakpoints, just do ipad and smaller and then everything else
  // 2/21/2023 changed from 820 to 750.
  if (minInnerSide < 750) {
    //what is the desired width for the ui?
    //skip supersize on mobile, they can reverse pinch zoom
    const maxWidthToReturn = 300 * mobileMultiplier;
    // check if maxWidth above is evenly divisible by the maxSide
    // if not, find the next highest number that is evenly divisible by the maxSide
    // by subtracting the remainder
    const actualWidthToReturn = maxWidthToReturn - (maxWidthToReturn % maxSide);
    console.log(
      'minInnerSide, maxInnerSide, actualWidthToReturn',
      minInnerSide,
      maxInnerSide,
      actualWidthToReturn
    );
    return actualWidthToReturn / maxSidePixels;
  }
  if (minInnerSide < 1000) {
    //what is the desired width for the ui?
    const maxWidthToReturn = 540 * multiplier;
    // check if maxWidth above is evenly divisible by the maxSide
    // if not, find the next highest number that is evenly divisible by the maxSide
    // by subtracting the remainder
    const actualWidthToReturn = maxWidthToReturn - (maxWidthToReturn % maxSide);
    console.log(
      'minInnerSide, maxInnerSide, actualWidthToReturn',
      minInnerSide,
      maxInnerSide,
      actualWidthToReturn
    );
    return actualWidthToReturn / maxSidePixels;
  }
  //everything else 2/21/23 changed from 560 to 540
  // 7/2023 added large screen = 700
  const maxWidthToReturn = 700 * multiplier;
  const actualWidthToReturn = maxWidthToReturn - (maxWidthToReturn % maxSide);
  console.log(
    'minInnerSide, maxInnerSide, actualWidthToReturn',
    minInnerSide,
    maxInnerSide,
    actualWidthToReturn
  );
  return actualWidthToReturn / maxSidePixels;
};

export const shortId = () =>
  (Math.random() * Math.random()).toString(36).substr(2);

export function money(num = 0, currency) {
  return (CURRENCIES[currency] || '$') + Number(num).toFixed(2);
}

export function allowedFile(allowedExts, filename) {
  if (!allowedExts.length) return true;
  let valid = false;
  each(allowedExts, (ext) => {
    const extRegex = new RegExp('\\.' + ext + '$', 'i');

    if (filename.match(extRegex) != null) {
      valid = true;
      return false;
    }
  });

  return valid;
}

export function isImage(file) {
  const isImage = REGEXP_MIME_TYPE_IMAGES.test(file.type);
  const isTiff = TIFF_MIME_TYPE_IMAGES.test(file.type);
  return isImage && !isTiff;
}

export function isValidTemplate(product) {
  const { template, width, height, allowEmptyNodes } = product;
  if (allowEmptyNodes) return true;
  if (!width || !height) return false;
  if (!template) return false;
  if (!template.nodes.length) {
    return false;
  }
  const imgNode = template.nodes[0];
  if (imgNode.type !== 'image') return false;
  if (!imgNode.width || !imgNode.height) return false;
  return true;
}

export function suffixFilename(name, suffix) {
  const parts = name.split('.');
  if (parts.length > 1) {
    const ext = parts.pop();
    return parts.join('.') + suffix + '.' + ext;
  } else {
    return name + suffix;
  }
}

export function getFileHash(file) {
  if (!file || typeof file.size === 'undefined') {
    console.error('Invalid file object passed to getFileHash');
    return 'inv_file';
  }
  const { name, size } = file;
  return suffixFilename(name, `_${size.toString(36)}`);
}

export function parseLinkedNodes(value) {
  if (value) {
    const matches = value.match(/\(([^)]+)\)/g);
    if (matches) {
      return matches.map((group) => {
        const nodes = group.replace('(', '').replace(')', '').split(',');
        return nodes;
      });
    }
  }
}

export function getSiblingNodes(nodeId, nodes) {
  if (nodes && nodeId) {
    const group = nodes.find((group) => group.includes(nodeId));
    if (group) {
      return group.filter((id) => id !== nodeId);
    }
  }
}

export function parseTextTemplate(value) {
  const matches = value.match(
    /(\{[A-Z\d:fileName|fileNameSpecial|fileExt]+\})/g
  );
  if (matches) {
    return matches.map((match) => ({
      variable: match,
      segments: match.replace('{', '').replace('}', '').split(':'),
    }));
  }
}

export const getNextAlphabet = (alphabet) => {
  if (!alphabet) return 'A';
  const nextAlphabet = alphabet.charCodeAt(0) + 1;
  return String.fromCharCode(nextAlphabet);
};

export const getFileNameParts = (fileName) => {
  const fileNameParts = fileName.split('.');
  const fileExt = fileNameParts.pop();
  const fileNameWithoutExt = fileNameParts.join('.');
  // fileNameSpecial assumes filename is in the form of lastname, firstname.ext
  // if there is a comma, split the string and return the second part with a space and then the first part
  const commaIndex = fileNameWithoutExt.indexOf(',');
  let fileNameSpecial = fileNameWithoutExt;
  if (commaIndex > -1) {
    const parts = fileNameWithoutExt.split(',');
    fileNameSpecial = `${parts[1].trim()} ${parts[0].trim()}`;
  }
  if (fileNameWithoutExt.includes('no-text')) {
    fileNameSpecial = '';
  }
  return { fileName: fileNameWithoutExt, fileExt, fileNameSpecial };
};
