import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { money } from 'utils';
import { getTirePrice } from 'utils/priceHelpers';

const useStyles = makeStyles((theme) => ({
  requiredOptions: {
    margin: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    width: '100%',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(0.25),
      maxWidth: '95%',
      padding: theme.spacing(0.25),
      marginBottom: theme.spacing(2),
    },
  },
  optionSelect: {
    width: 250,
    flexShrink: 0,
    '& .MuiOutlinedInput-input': {
      padding: '6px 12px',
    },
    '& .MuiSelect-select': {
      minHeight: 'unset',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  optionLabel: {
    fontWeight: 500,
    fontSize: '0.9rem',
    width: 120,
    textAlign: 'right',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 400,
      textAlign: 'left',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: theme.spacing(0.5),
      marginBottom: theme.spacing(0.25),
    },
  },
  warningMessage: {
    color: theme.palette.error.main,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    fontWeight: 700,
    fontSize: '1rem',
    height: '24px',
    visibility: (props) => (props.hasSelectedOptions ? 'hidden' : 'visible'),
    opacity: (props) => (props.hasSelectedOptions ? 0 : 1),
    transition: 'opacity 0.2s ease',
  },
  optionsContainer: {
    width: '100%',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const RequiredOptionsSelect = ({ product, value, onChange, currency }) => {
  const classes = useStyles({
    hasSelectedOptions: product?.allOptions?.every(
      (option) => value?.[option.id]
    ),
  });

  // If there are no options configured but options are required,
  // return null to prevent the render error
  if (!product?.allOptions || product.allOptions.length === 0) {
    return null;
  }

  // Check if any options are selected
  const hasSelectedOptions = product.allOptions.every(
    (option) => value?.[option.id]
  );

  const getOptionPrice = (option, choicePrice) => {
    const tirePrice = getTirePrice(choicePrice, 1); // Using quantity 1 for display
    return tirePrice;
  };

  return (
    <Box className={classes.optionsContainer}>
      <Typography className={classes.warningMessage}>
        Required Options - Please Select:
      </Typography>
      {product?.allOptions?.map((option) => (
        <Box key={option.id} className={classes.requiredOptions}>
          <Typography className={classes.optionLabel}>
            {option.description}:
          </Typography>
          <FormControl variant="outlined" className={classes.optionSelect}>
            <Select
              native
              value={value?.[option.id] || 'select'}
              onChange={(e) => {
                const newValue = e.target.value;
                onChange({
                  ...value,
                  [option.id]: newValue === 'select' ? undefined : newValue,
                });
              }}
            >
              <option value="select">Select an Option</option>
              {option.choices.map((choice) => {
                const price = getOptionPrice(option, choice.price);
                const label =
                  price > 0
                    ? `${choice.label} - ${money(price, currency)}`
                    : choice.label;
                return (
                  <option key={choice.id} value={choice.id}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      ))}
    </Box>
  );
};

export default RequiredOptionsSelect;
