import { useState } from 'react';
import { Box, Container, Typography, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import axios from 'axios';

const CachePage = () => {
  const lab = useSelector((s) => s.lab);
  const [status, setStatus] = useState('Click the button to refresh the cache');
  const [isLoading, setIsLoading] = useState(false);

  const refreshCache = async () => {
    setIsLoading(true);
    try {
      await axios.post(`/api/clear-lab-cache/${lab.lab_id}`);
      setStatus('Cache cleared! Reloading page...');
      // Wait a moment to show the success message, then reload
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      setStatus('Error refreshing cache: ' + error.message);
      setIsLoading(false);
    }
  };

  return (
    <Box marginTop={2}>
      <Container>
        <Typography variant="h1">Cache Management</Typography>
        <Typography paragraph>{status}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={refreshCache}
          disabled={isLoading}
        >
          {isLoading ? 'Refreshing...' : 'Refresh Cache'}
        </Button>
      </Container>
    </Box>
  );
};

export default CachePage;
