import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import QuantityInput from 'components/QuantityInput';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormat from 'react-number-format';
import Typography from '@material-ui/core/Typography';

const CartOptions = ({
  onAdd,
  quantity,
  onChange,
  editing,
  hasItems,
  hasImage,
  emptyNodesOk,
  category,
  onToggleSettings,
  optionsRequired,
  hasOptions,
  productOptions,
  hasValidOptions,
}) => {
  const allowCheckout = hasImage || emptyNodesOk;

  // Check if productOptions is an object with any keys
  const hasSelectedOptions =
    productOptions && Object.keys(productOptions).length > 0;

  const isAddDisabled =
    (optionsRequired && !hasSelectedOptions) || (!hasImage && !emptyNodesOk);

  const addButtonTooltip = () => {
    if (optionsRequired && !hasSelectedOptions)
      return 'Please select required options at the top';
    if (!hasImage && !emptyNodesOk) return 'Not all images are selected';
    return '';
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={1}
        mb={2}
      >
        <Hidden smDown>
          {/* hide view cart on mobile using another button below */}
          <Box mr={2}>
            <Button
              disabled={!hasItems}
              component={Link}
              to="/cart"
              variant="outlined"
              color="secondary"
            >
              View Cart
            </Button>
          </Box>
        </Hidden>
        <Box marginX={2}>
          <QuantityInput value={quantity} onChange={onChange} />
        </Box>
        <span>
          <Tooltip title={addButtonTooltip()}>
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onAdd()}
                disabled={
                  (!hasImage && !emptyNodesOk) ||
                  (optionsRequired && !hasValidOptions)
                }
              >
                {editing ? 'Update Cart' : 'Add to Cart'}
              </Button>
            </span>
          </Tooltip>
        </span>
      </Box>

      {editing ? (
        ''
      ) : (
        <Hidden mdUp>
          <Box my={2}>
            <ButtonGroup variant="outlined">
              <Button onClick={onToggleSettings}>Settings</Button>
              <Button to={`/choose-product/${category}`} component={Link}>
                Change Size
              </Button>
              <Button
                disabled={!hasItems}
                component={Link}
                color="secondary"
                to="/cart"
              >
                View Cart
              </Button>
            </ButtonGroup>
            {optionsRequired && !hasValidOptions && (
              <Typography
                variant="caption"
                color="error"
                align="center"
                style={{
                  display: 'block',
                  marginTop: 8,
                  fontSize: '0.8rem',
                }}
              >
                Please select required options (top) to add to cart
              </Typography>
            )}
          </Box>
        </Hidden>
      )}
    </>
  );
};

export default CartOptions;
