import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ImageSettings from '../ImageSettings';
import TextSettings from './TextSettings';
import ColorInput from '../ColorInput';
import { withStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';

const Accordion = withStyles((theme) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .3)',
    boxShadow: 'none',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    '& .MuiAccordionSummary-root': {
      height: 40,
      minHeight: 'unset',
      padding: '0 12px',
      '&$expanded': {
        minHeight: 'unset',
      },
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      '&$expanded': {
        margin: 0,
      },
    },
  },
  expanded: {},
}))(MuiAccordion);

const NodesSettings = ({
  onUpdateNode,
  onUpdateNodes,
  onBackgroundColorChange,
  product,
  onImageSelect,
}) => {
  const isMobile = useMediaQuery('(max-width:960px)');
  const [expandAccordion, setExpandAccordion] = useState(false);

  useEffect(() => {
    setExpandAccordion(isMobile);
  }, [isMobile]);

  const onAccordionChange = (e, isExpanded) => {
    setExpandAccordion(isExpanded);
  };

  return (
    <Box mb={2} mt={2}>
      <Accordion
        elevation={0}
        expanded={expandAccordion}
        onChange={onAccordionChange}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              border={1}
              padding={1}
              marginBottom={1}
            >
              <Box marginRight={2} display="flex" alignItems="center">
                <Typography variant="body2" style={{ marginRight: 6 }}>
                  Background Color:{' '}
                </Typography>
                <ColorInput
                  value={product.template.backgroundColor}
                  onChange={onBackgroundColorChange}
                />
              </Box>
            </Box>
            <Box>
              <TextSettings
                onChange={onUpdateNode}
                nodes={product.template.nodes.filter((i) => i.type === 'text')}
              />
              <ImageSettings
                onUpdateNodes={onUpdateNodes}
                onImageSelect={onImageSelect}
                onChange={onUpdateNode}
                nodes={product.template.nodes.filter((i) => i.type === 'image')}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default NodesSettings;
