import React from 'react';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import CropIcon from '@material-ui/icons/Crop';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import Typography from '@material-ui/core/Typography';
import { isValidTemplate, money } from 'utils';
import Button from '@material-ui/core/Button';
import QuantityInput from 'components/QuantityInput';
import { updateItem } from 'store/actions/cart';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import ItemImage from './ItemImage';
import { getTirePrice } from 'utils/priceHelpers';
import * as Sentry from '@sentry/react';

const TableRow = ({
  price,
  lab,
  showBatchThumbnail,
  classes,
  i, // lineItem
  catalogs,
  setShowOptions,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const renderTextValues = (lineItem) => {
    const textValues = lineItem.product.template.nodes.filter(
      (node) => node.type === 'text'
    );
    if (textValues) {
      const text = textValues.map(
        (item) =>
          `${item.caption}  ${item.text ? `(Font:${item.fontFamily})` : ''}: ${
            item.text
          }`
      );
      return (
        <Typography variant="caption" component="p">
          {text.map((item, i) => (
            <span key={i}>
              {item}
              <br />
            </span>
          ))}
        </Typography>
      );
    }
    return null;
  };
  const renderOptionsSelected = (lineItem) => {
    if (lineItem.selectedOptions) {
      const selectedOptions = Object.keys(lineItem.selectedOptions).map(
        (key) => {
          const option = lineItem.product.allOptions.find((o) => o.id === key);
          const choice = option.choices.find(
            (c) => c.id === lineItem.selectedOptions[key]
          );
          const choicePriceUnit = getTirePrice(choice.price, lineItem.quantity);
          return {
            label: choice.label,
            price: choicePriceUnit,
          };
        }
      );
      const optionsText = selectedOptions
        .map(
          (i) => `${i.label} - ${money(i.price, lab.globalOptions.currency)}`
        )
        .join(', ');
      return (
        <Typography variant="caption" component="p">
          {optionsText}
        </Typography>
      );
    }
    return null;
  };

  return (
    <>
      <td>
        <ItemImage
          nodes={i.product.template?.nodes}
          showBatchThumbnail={showBatchThumbnail}
          classes={classes}
        />
      </td>
      <td>
        {!i.product.template?.nodes[0]?.crop &&
          i.product.template.nodes[0].image && (
            <Tooltip title="Cropping not checked">
              <CropIcon color={i?.requireCropping ? 'error' : 'primary'} />
            </Tooltip>
          )}
        {i.product.template?.nodes.some(
          (n) => n.type === 'text' && (!n.text || n.text === 'Your Text Here')
        ) && (
          <Tooltip title="Text not replaced">
            <TextFieldsIcon />
          </Tooltip>
        )}
      </td>
      <td>
        <Typography variant="body1">
          {i.product.size}
          {price?.lineItems[i.id]?.rate > 0 &&
            ` - ${money(
              price?.lineItems[i.id]?.rate,
              lab.globalOptions.currency
            )} each`}
        </Typography>
        <Typography variant="caption" component="p">
          {
            catalogs.find(
              (c) => c.cat_id === i.category || c.cat_seo === i.category
            ).cat_name
          }
        </Typography>
        {renderOptionsSelected(i)}
        {renderTextValues(i)}
        {i.product.allOptions?.length > 0 && (
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.optionsButton}
            onClick={() => setShowOptions(i)}
          >
            Options
          </Button>
        )}
      </td>
      <td>
        <QuantityInput
          value={i.quantity}
          onChange={(val) =>
            dispatch(
              updateItem({
                ...i,
                quantity: val,
              })
            )
          }
        />
      </td>
      <td>
        {price
          ? money(price.lineItems[i.id]?.totalPrice, lab.globalOptions.currency)
          : ''}
      </td>
      <td>
        {isValidTemplate(i.product) && (
          <Tooltip title="Edit Cropping">
            <IconButton
              onClick={() => Sentry.captureMessage('Edit Icon in cart clicked')}
              component={Link}
              to={`/editor/${i.category}/${i.product.seo || i.product.id}/${
                i.id
              }`}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Remove this item from the cart">
          <IconButton onClick={() => onDelete(i)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </td>
    </>
  );
};

export default TableRow;
