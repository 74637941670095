import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { money } from 'utils';
import { getTirePrice } from 'utils/priceHelpers';

const useStyles = makeStyles((theme) => ({
  optionContainer: {
    marginBottom: theme.spacing(2),
  },
  optionSelect: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px',
    },
    '& .MuiSelect-select': {
      minHeight: 'unset',
    },
  },
  optionLabel: {
    fontWeight: 500,
    fontSize: '0.9rem',
    marginBottom: theme.spacing(1),
  },
  mobileContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
}));

const ProductOptions = ({ product, value, onChange, currency }) => {
  const classes = useStyles();

  if (!product?.allOptions || product.allOptions.length === 0) {
    return null;
  }

  const getOptionPrice = (option, choicePrice) => {
    const tirePrice = getTirePrice(choicePrice, 1); // Using quantity 1 for display
    return tirePrice;
  };

  return (
    <Box mt={2} className={classes.mobileContainer}>
      {product.allOptions.map((option) => (
        <Box key={option.id} className={classes.optionContainer}>
          <Typography className={classes.optionLabel}>
            {option.description} Options
          </Typography>
          <FormControl variant="outlined" className={classes.optionSelect}>
            <Select
              native
              value={value?.[option.id] || ''}
              onChange={(e) => {
                const newValue = e.target.value;
                onChange({
                  ...value,
                  [option.id]: newValue || undefined,
                });
              }}
            >
              <option value="">Select an Option</option>
              {option.choices.map((choice) => {
                const price = getOptionPrice(option, choice.price);
                const label = `${choice.label} - ${money(price, currency)}`;
                return (
                  <option key={choice.id} value={choice.id}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      ))}
    </Box>
  );
};

export default ProductOptions;
