import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import TextField from 'components/form/Formik/FormikInput';
import Select from 'components/form/Formik/FormikReactSelect';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import times from 'lodash/times';
import { Form, Field, Formik, FieldArray } from 'formik';
import Button from 'components/Button';
import * as Yup from 'yup';
import { addPackage, removePackage } from 'store/actions/packages';
import * as Sentry from '@sentry/react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

const QTY = times(10, (i) => ({ label: i + 1, value: i + 1 }));

const validateSchema = Yup.object().shape({
  packageName: Yup.string().required('Required'),
  items: Yup.array().of(
    Yup.object().shape({
      productId: Yup.string().required('Required'),
      quantity: Yup.number().required('Required'),
    })
  ),
});

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    minHeight: 65, // Provides space for error message
    marginBottom: theme.spacing(1),
  },
  selectField: {
    marginBottom: 0,
  },
}));

const CreatePackage = ({ products, catalog, pkg, onClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const onSubmit = async (values) => {
    if (pkg) {
      console.log('package values :>> ', values);
      Sentry.captureMessage('Package edited');
      dispatch(removePackage(pkg.id));
      dispatch(
        addPackage({
          ...pkg,
          ...values,
        })
      );
    } else {
      console.log(`Package name: ${values.packageName}`);
      console.log(JSON.stringify(values.items, null, 2));
      Sentry.captureMessage('Package added');
      values.catId = catalog.cat_id;
      dispatch(addPackage(values));
    }
    onClose();
  };

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Create A Package</DialogTitle>
      <DialogContent>
        Package info is stored in your browsers' local storage. If you change
        browsers, clear your browser cache or use a different computer you will
        need to re-enter your packages.
      </DialogContent>
      <Formik
        validationSchema={validateSchema}
        initialValues={
          pkg || {
            packageName: '',
            items: [{ productId: '', quantity: '' }],
          }
        }
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, handleSubmit }) => {
          return (
            <>
              <DialogContent>
                <Form>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="packageName"
                      component={TextField}
                      fullWidth
                      placeholder="Package name"
                    />
                  </div>
                  <FieldArray name="items">
                    {({ push, remove }) => {
                      return values.items.map((item, index) => (
                        <Grid
                          container
                          spacing={1}
                          alignItems="flex-start"
                          key={index}
                          className={classes.fieldContainer}
                        >
                          <Grid item xs={6}>
                            <Field
                              name={`items[${index}].productId`}
                              component={Select}
                              fullWidth
                              transformValue
                              menuPosition="fixed"
                              placeholder="Select product"
                              className={classes.selectField}
                              options={products
                                .filter((i) => i.template.nodes.length === 1)
                                .map((i) => ({
                                  label: i.size,
                                  value: i.id,
                                }))}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Field
                              name={`items[${index}].quantity`}
                              component={Select}
                              fullWidth
                              transformValue
                              menuPosition="fixed"
                              placeholder="Quantity"
                              className={classes.selectField}
                              options={QTY}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            {values.items.length > 1 && (
                              <IconButton
                                onClick={() => remove(index)}
                                color="secondary"
                              >
                                <Remove />
                              </IconButton>
                            )}
                            {index === values.items.length - 1 && (
                              <IconButton
                                onClick={() =>
                                  push({ productId: '', quantity: '' })
                                }
                                color="primary"
                              >
                                <Add />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      ));
                    }}
                  </FieldArray>
                </Form>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} disabled={isSubmitting}>
                  {pkg ? 'Update' : 'Create'}
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default CreatePackage;
