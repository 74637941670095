import { useRef, useContext, useState, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import ProductCard from 'components/ProductCard';
import { useSelector } from 'react-redux';
import { isValidTemplate } from 'utils';
import { Link } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles({
  products: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  file: {
    visibility: 'hidden',
  },
});

const ChooseProduct = ({
  match: {
    params: { category },
  },
  history,
}) => {
  const classes = useStyles();
  const catalogs = useSelector((s) => s.catalogs);
  const catalog = useMemo(
    () => catalogs.find((c) => c.cat_seo === category || c.cat_id === category),
    [category, catalogs]
  );

  if (!catalog) {
    Sentry.captureMessage(`Catalog not found: ${category}`);
    return (
      <Container maxWidth="sm">
        <Box textAlign="center" mt={8} mb={4}>
          <Alert
            severity="warning"
            icon={false}
            style={{
              backgroundColor: 'rgba(255, 244, 229, 0.9)',
              marginBottom: '24px',
            }}
          >
            <AlertTitle style={{ marginBottom: '16px' }}>
              Category Not Found
            </AlertTitle>
            <Typography variant="body1" style={{ marginBottom: '8px' }}>
              We couldn't locate the category you're looking for. This could be
              because:
            </Typography>
            <Typography
              variant="body2"
              component="div"
              style={{ textAlign: 'left', color: 'rgba(0, 0, 0, 0.7)' }}
            >
              • The URL might be misspelled
              <br />
              • The category may have been removed
              <br />• The category may be temporarily unavailable
            </Typography>
          </Alert>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/"
            size="large"
            style={{
              padding: '12px 32px',
              borderRadius: '4px',
              textTransform: 'none',
              fontSize: '16px',
            }}
          >
            Go to Home Page
          </Button>
        </Box>
      </Container>
    );
  }

  const onTemplateClick = (tmpl) => (e) => {
    history.push(`/editor/${category}/${tmpl.seo || tmpl.id}`);
  };

  return (
    <Box my={2}>
      <Helmet title={`Choose product - ${catalog.cat_name}`}>
        <meta name="description" content="Choose product to order." />
      </Helmet>
      <Container>
        <Typography align="center" variant="h1">
          {catalog.cat_name}
        </Typography>

        <Typography paragraph align="center">
          {catalog.cat_description}
        </Typography>
        <Box className={classes.products}>
          {catalog.products.filter(isValidTemplate).map((i) => (
            <ProductCard
              key={i.id}
              product={i}
              showPrice={catalog.cat_show_prices}
              onSelect={onTemplateClick(i)}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default ChooseProduct;
