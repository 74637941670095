import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './containers/home';
import Editor from './containers/editor';
import Cart from './containers/cart';
import Checkout from './containers/checkout';
import Contact from './containers/contact';
import Help from './containers/help';
import Success from './containers/success';
import Summary from './containers/summary';
import NotFound from './containers/404';
import Tracker from './components/Tracker';
import BatchOrder from './containers/batchOrder';
import ChooseProduct from 'containers/choose-product';
import { AlertProvider } from 'context/alert';
import AlertComponent from './components/AlertComponent';
import ScrollTop from './components/ScrollTop';
import RestoreCart from './components/RestoreCart';
import CachePage from './containers/cacheRefresh';

// sentry replay causes refresh during dev, only load if not dev
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    release: '3-29-2025 all options',
    dsn: 'https://a74af258c3e04876b094abe636ca94f5@o49126.ingest.sentry.io/6035037',
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'Non-Error promise rejection captured',
      'UnhandledRejection',
      'ResizeObserver loop completed with undelivered notifications',
    ],
    //replaysSessionSampleRate: 0.1,
    //If the entire session is not sampled, use the below sample rate to sample
    //sessions when an error occurs.
    // replaysOnErrorSampleRate: 1.0,

    // integrations: [
    //   new Sentry.Replay({
    //     maskAllText: true,
    //   }),
    // ],
    //  });
  });
}

function App() {
  return (
    <SnackbarProvider
      dense
      maxSnack={3}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={1500}
    >
      <AlertProvider component={AlertComponent}>
        <div className="App">
          <CssBaseline />
          <Helmet />
          <Tracker />
          <Navbar />
          <ScrollTop />
          <RestoreCart />
          <Box flex={1}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                exact
                path="/editor/:category/:id?/:itemId?"
                component={Editor}
              />
              <Route exact path="/cart" component={Cart} />
              <Route path="/checkout" component={Checkout} />
              <Route exact path="/success" component={Success} />
              <Route exact path="/summary" component={Summary} />
              <Route exact path="/contact" component={Contact} />
              <Route
                exact
                path="/batch-order/:category"
                component={BatchOrder}
              />
              <Route
                exact
                path="/choose-product/:category"
                component={ChooseProduct}
              />
              <Route exact path="/help" component={Help} />
              <Route exact path="/cache" component={CachePage} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Box>
          <Footer />
        </div>
      </AlertProvider>
    </SnackbarProvider>
  );
}

export default App;
